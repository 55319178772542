import {Alert, Box, Button, Container, SelectChangeEvent} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams} from "@mui/x-data-grid";
import {searchCacheGet, searchCacheSet} from "../../dataGrid/searchCache";
import {CustomDataGrid} from "../../dataGrid/dataGrid.component";
import {Banner} from "../../banner/banner.component";
import {useEquipmentCount, useEquipmentInfo} from "../installation.hooks";

interface gridProps {
    searchCacheKey: string;
    equipmentSearch: string;
    useColumnFilters?: boolean;
}

export function EquipmentGrid({searchCacheKey, equipmentSearch, useColumnFilters}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>({ object_id: false});

    const filter = useRef<any[] | undefined>(undefined);
    const rowCount = useEquipmentCount(equipmentSearch);
    const equipmentInfo = useEquipmentInfo(equipmentSearch, paginationModel.page, paginationModel.pageSize, true);
    const navigate = useNavigate();

    const columnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false, sortable: false},
        {field: "name", headerName: "Name", minWidth: 160, flex: 1, editable: false, hideable: false, sortable: false},
        {field: "id", headerName: "Identifier", minWidth: 140, flex: 1, editable: false, hideable: false, sortable: false},
        {field: "type", headerName: "Type", minWidth: 110, flex: 1, editable: false, hideable: false, sortable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    const identifier = params.row.id;

                    switch (params.row.type) {
                        case "Module":
                            navigate({pathname: `/engine_data/${objectId}+${identifier}`})
                            break;

                        case "Asset Scan":
                            navigate({pathname: `/asset_scan_data/${objectId}+${identifier}`})
                            break;

                        case "ES&O":
                            navigate({pathname: `/eso_data/${objectId}+${identifier}`})
                            break;

                        case "Plantnet":
                            navigate({pathname: `/plantnet/${objectId}+${identifier}`})
                            break;

                        case "SES":
                            navigate({pathname: `/ses_data/${objectId}+${identifier}`})
                            break;

                        case "SCR":
                            navigate({pathname: `/scr_data/${objectId}+${identifier}`})
                            break;

                        case "WDCU":
                            navigate({pathname: `/wdcu_data/${objectId}+${identifier}`})
                            break;

                        default:
                            console.warn("View page could not be loaded");
                            break;
                    }
                }

                return(
                    <Button
                        style={{
                            float: "right"}}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
    })

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    return(
        <Box>
            {equipmentInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!equipmentInfo.isError && (
                <Box>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            columnConfig={columnConfig}
                            itemInfo={equipmentInfo.data}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={rowCount.data !== undefined ? rowCount.data : 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={equipmentInfo.isLoading}
                            useFilterHeader={useColumnFilters}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function Equipment(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");

    // eslint-disable-next-line prefer-destructuring
    const equipmentSearch = parts[2];

    return(
        <Box>
            <Banner firstLine="Equipment Data" secondLine={`Search results for "${equipmentSearch}"`} gridBanner/>
            <Container maxWidth="xl">
                <EquipmentGrid searchCacheKey="equipment" equipmentSearch={equipmentSearch}/>
            </Container>
        </Box>
    );
}
